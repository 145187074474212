import { db } from "@/main";

const mokShiftsChunksCollection = "shiftPlanningChunks";

export const getMokShiftsFile = async (service, monthKey) => {
  try {
    //combine from chunks
    if (service === "neighborhood" || service === "mokLit") {
      let getDocs = await db
        .collection(mokShiftsChunksCollection)
        .where("service", "==", service)
        .where("monthKey", "==", monthKey)
        .get();

      let shiftsDoc = {};
      getDocs.forEach((doc) => {
        shiftsDoc = Object.assign(shiftsDoc, doc.data());
      });
      return shiftsDoc;
    } else {
      //the old way (e.g. mokLit)
      const shiftsDocKey = `${service}-${monthKey}`;
      let getDoc = await db.collection("shiftPlanning").doc(shiftsDocKey).get();

      return getDoc.exists ? getDoc.data() : {};
    }
  } catch (error) {
    console.error(error);
    alert("Error: setMokShiftsFile", error);
  }
};
